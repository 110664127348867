/** @jsx jsx */
import { jsx, Link as TLink } from "theme-ui"
import useBlogConfig from "../hooks/use-blog-config"
import useSiteMetadata from "../hooks/use-site-metadata"

const Footer = () => {
  const { siteName } = useSiteMetadata()
  const { externalLinks } = useBlogConfig()

  return (
    <footer
      sx={{
        boxSizing: `border-box`,
        display: `flex`,
        justifyContent: `space-between`,
        mt: [6],
        color: `secondary`,
        a: {
          variant: `links.secondary`,
        },
        flexDirection: [`column`, `column`, `row`],
        variant: `dividers.top`,
      }}
    >
      <div>
        &copy; {new Date().getFullYear()} {siteName} by Venelin Valkov
      </div>
      <div>
        {externalLinks && externalLinks.length > 0 && (
          <div sx={{ "a:not(:first-of-type)": { ml: 3 } }}>
            {externalLinks.map(link => (
              <TLink key={link.url} href={link.url} target="_blank">
                {link.name}
              </TLink>
            ))}
          </div>
        )}
      </div>
    </footer>
  )
}

export default Footer
