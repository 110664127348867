/** @jsx jsx */
import { Link } from "gatsby"
import { jsx } from "theme-ui"
import useBlogConfig from "../hooks/use-blog-config"
import useSiteMetadata from "../hooks/use-site-metadata"
import replaceSlashes from "../utils/replaceSlashes"

const HeaderTitle = () => {
  const { siteName } = useSiteMetadata()
  const { basePath } = useBlogConfig()

  return (
    <Link
      to={replaceSlashes(`/${basePath}`)}
      aria-label={`${siteName} - Back to home`}
      sx={{ color: `heading`, textDecoration: `none` }}
    >
      <h1 sx={{ my: 0, fontWeight: `medium`, fontSize: [3, 4] }}>{siteName}</h1>
    </Link>
  )
}

export default HeaderTitle
