/** @jsx jsx */
import { Global } from "@emotion/core"
import React from "react"
import { Box, Container, jsx } from "theme-ui"
import "typeface-ibm-plex-sans"
import CodeStyles from "../styles/code"
import Footer from "./footer"
import Header from "./header"
import SEO from "./seo"
import SkipNavLink from "./skip-nav"
require(`katex/dist/katex.min.css`)

const Layout = ({ children, className }) => (
  <React.Fragment>
    <Global
      styles={theme => ({
        ".gatsby-resp-image-image": {
          width: "100%",
          height: "100%",
          margin: 0,
          verticalAlign: "middle",
          position: "absolute",
          top: 0,
          left: 0,
        },
        "*": {
          scrollBehavior: `smooth`,
          boxSizing: `inherit`,
        },
        "::selection": {
          backgroundColor: theme.colors.primary,
          color: theme.colors.white,
        },
        a: {
          transition: `all 0.3s ease-in-out`,
          color: `text`,
        },
      })}
    />
    <SEO />
    <SkipNavLink>Skip to content</SkipNavLink>
    <Container>
      <Header />
      <Box id="skip-nav" sx={{ ...CodeStyles }} className={className}>
        {children}
      </Box>
      <Footer />
    </Container>
  </React.Fragment>
)

export default Layout
